<template>
    <el-dialog title="基站设备信息详情" :visible.sync="deviceDataDetailPageVisible" top="3vh"
           custom-class="option-dialog-style" @close="closeDeviceDataDetailPage" :close-on-click-modal="false" :destroy-on-close=true>
        <el-tabs v-model="activeName" tab-position="left" ref="tabs" class="module-tabs-style" @tab-click="handleTabChangeEvent">
            <el-tab-pane v-for="(tab) in commandInfoList" :label="tab.commandName" :name="tab.commandName" :commandCode="tab.commandCode">
                <div style="margin: 0 0 10px 0">
                    <el-button size="mini" type="primary" @click="openCompareFieldDetailPage(tab)">对比</el-button>
                    <span class="report-time">上报时间(中国)：{{ lastReportTime }}</span>
                </div>
                <el-table :ref="getTableRef(tab)" :data="realDataVoList" max-height="680" size="small" stripe border
                          @selection-change="handleSelection"
                          :header-cell-style="{background: '#eef1f6', color: '#606266'}"
                          :cell-class-name="getCellClassName"
                          :header-cell-class-name="getHeaderCellClass">
                    <el-table-column type="selection" align="center" width="50" :selectable="handleSelectableEvent"/>
                    <el-table-column type="index" label="序号" align="center" width="50"/>
                    <el-table-column prop="fieldCn" label="中文含义" align="left" sortable/>
                    <el-table-column prop="fieldEn" label="英文含义" align="left"/>
                    <el-table-column prop="value" label="数值" align="center"/>
                    <el-table-column prop="unit" label="单位" align="center" width="70"/>
                    <el-table-column prop="state" label="状态" align="center" width="70" :formatter="stateFormatter"/>
                    <el-table-column label="操作" align="center" width="100">
                        <template slot-scope="scope">
                            <div class="statistics" v-if="scope.row.showChart === true">
                                <el-tooltip v-delTabIndex effect="dark" content="统计" placement="top">
                                    <i class="statistics-btn option-btn-normal" @click="openFieldDetailPage(scope.row, tab)"/>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-dialog width="1220px" :visible.sync="tab.lineChartDialogVisible" :close-on-click-modal="false"
                           append-to-body :before-close="closeLineChartDialog">
                    <div style="text-align: right">
                        <el-date-picker v-model="reportTimeRange" type="daterange" value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期" end-placeholder="结束日期"
                                        @change="handleReportTimeRangeChangeEvent" :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                    <div v-show="haveData" class="have-data">暂无数据</div>
                    <div :id="getLineChartId(tab)" style="width: 1200px; height: 500px"></div>
                </el-dialog>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
    import {
        queryDeviceDataCommandFieldChart,
        queryDeviceDataCommandFieldValueList,
        queryDeviceDataCommandList
    } from '@/api/es/device/esDeviceInfo';
    import Global from '@/components/Global';
    import DateUtil from '@/utils/dateUtil';
    import message from '@/utils/message';

    export default {

        name: 'device-data-detail',
        data() {

            let self = this;
            return {

                deviceSn: null,
                eCharts: require('echarts'),
                lastReportTime: '--',
                commandInfoList: [],
                realDataVoList: [],
                activeName: '',
                deviceDataDetailPageVisible: false,
                option: {

                    legendArray: [],
                    xAxis: [],
                    yAxis: {

                        type: 'value',
                    },
                    series: []
                },
                lineChartSearchForm: {

                    deviceSn: '',
                    commandCode: '',
                    fieldList: [],
                    startDate: '',
                    endDate: '',
                },
                firstSelectDate: '',
                reportTimeRange: [],
                pickerOptions: {

                    onPick({maxDate, minDate}) {

                        if (minDate && !maxDate) {

                            self.firstSelectDate = minDate.getTime();
                        }
                        if (maxDate) {

                            self.firstSelectDate = '';
                        }
                    },
                    // 只允许查询7天范围的数据
                    disabledDate(time) {

                        if (self.firstSelectDate) {

                            let timeRange = 6 * 24 * 60 * 60 * 1000;
                            return time.getTime() > self.firstSelectDate + timeRange ||
                                time.getTime() < self.firstSelectDate - timeRange || time.getTime() > Date.now();
                        } else {

                            return time.getTime() > Date.now();
                        }
                    }
                },
                haveData: false,
                row: {},
                dataFieldEnum: {

                }
            }
        },
        methods: {

            initDeviceDetailPage(deviceSn) {

                this.deviceDataDetailPageVisible = true;
                this.deviceSn = deviceSn;
                this.queryDeviceDataCommandList();
                this.lineChartSearchForm.deviceSn = deviceSn;
            },
            queryDeviceDataCommandList() {

                let param = {

                    sn: this.deviceSn,
                }
                queryDeviceDataCommandList(param).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.commandInfoList = res.data;
                        if (this.commandInfoList && this.commandInfoList.length > 0) {

                            // 设置默认展示的tab页面
                            this.activeName = this.commandInfoList[0].commandName;
                            // 加载列表数据
                            this.loadRealData(this.deviceSn, this.commandInfoList[0].commandCode);
                            this.lineChartSearchForm.commandCode = this.commandInfoList[0].commandCode;
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            handleTabChangeEvent(tab) {

                this.loadRealData(this.deviceSn, tab.$attrs.commandCode);
                this.lineChartSearchForm.commandCode = tab.$attrs.commandCode;
            },
            handleReportTimeRangeChangeEvent() {

                this.queryDataCommandFieldChart(this.row);
            },
            // 加载指令实时数据
            loadRealData(deviceNum, commandCode) {

                let param = {

                    deviceSn: deviceNum,
                    commandCode: commandCode
                };
                queryDeviceDataCommandFieldValueList(param).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        res.data.fieldList.forEach((item) => {

                            item.selectionEnable = true;
                        });
                        this.realDataVoList = res.data.fieldList;
                        if (res.data.reportTime != null) {

                            this.lastReportTime = res.data.reportTime;
                        } else {

                            this.lastReportTime = '--';
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                })
            },
            closeDeviceDataDetailPage() {

                this.$refs.tabs.$destroy();
                this.deviceDataDetailPageVisible = false;
                this.$emit('getTableData');
            },
            closeLineChartDialog(done) {

                this.reportTimeRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
                this.eCharts.init(document.getElementById('lineChart_' + this.lineChartSearchForm.commandCode)).dispose();
                done();
            },
            openCompareFieldDetailPage(tab) {

                tab.lineChartDialogVisible = true;
                let length = eval('this.$refs.table_' + this.lineChartSearchForm.commandCode)[0].selection.length;
                if (length < 2) {

                    this.$message.error('对比不能少于两条数据');
                    return;
                }
                this.reportTimeRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
                this.queryDataCommandFieldChart(null);
            },
            // 查看折线图
            openFieldDetailPage(row, tab) {

                tab.lineChartDialogVisible = true;
                this.reportTimeRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
                this.queryDataCommandFieldChart(row);
            },
            // 查询指令字段报表
            queryDataCommandFieldChart(row) {

                let fieldMap = new Map();
                let unitMap = new Map();
                let fieldCnList = [];
                this.row = null;
                this.lineChartSearchForm.fieldList = [];
                this.lineChartSearchForm.startDate = this.reportTimeRange[0];
                this.lineChartSearchForm.endDate = this.reportTimeRange[1];
                if (row === null) {

                    // 多选比较
                    let data = eval('this.$refs.table_' + this.lineChartSearchForm.commandCode)[0].selection;
                    for (let index = 0; index < data.length; index++) {

                        let fieldEn = data[index].fieldEn;
                        let fieldCn = data[index].fieldCn;
                        let unit = data[index].unit;
                        fieldMap.set(fieldEn, fieldCn);
                        unitMap.set(fieldEn, unit);
                        fieldCnList[index] = fieldCn;
                        this.lineChartSearchForm.fieldList[index] = fieldEn;
                    }
                } else {

                    // 某一行
                    this.row = row;
                    fieldMap.set(row.fieldEn, row.fieldCn);
                    unitMap.set(row.fieldEn, row.unit);
                    fieldCnList = [row.fieldCn];
                    this.lineChartSearchForm.fieldList = [row.fieldEn];
                }
                this.option.legendArray = fieldCnList;
                // 查询指令字段数据
                queryDeviceDataCommandFieldChart(this.lineChartSearchForm).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.option.xAxis = res.data.reportTimeList;
                        this.option.series = [];
                        let valueMap = res.data.valueMap;
                        this.dataFieldEnum = res.data.enumsMap;
                        let i = 0;
                        for (let key in valueMap) {

                            this.option.series[i] = {};
                            this.option.series[i].name = fieldMap.get(key);
                            this.option.series[i].type = 'line';
                            this.option.series[i].emphasis = {};
                            this.option.series[i].emphasis.focus = 'series';
                            this.option.series[i]['data'] = [];
                            this.option.series[i]['unit'] = {};
                            this.option.series[i]['unit'] = unitMap.get(key);
                            this.option.series[i]['showSymbol'] = false;
                            this.haveData = false;
                            if (key === 'batChargeAndDischargeState') {

                                for (let j = 0; j < valueMap[key].length; j++) {

                                    let originalVal = valueMap[key][j];
                                    let val = 0;
                                    if (originalVal === '17') {

                                        val = 1;
                                    } else if (originalVal === '34') {

                                        val = 2;
                                    } else if (originalVal === '51') {

                                        val = 3;
                                    }
                                    valueMap[key][j] = val;
                                }
                            }
                            this.option.series[i]['data'] = valueMap[key];
                            i++;
                        }
                        let that = this;
                        this.option.yAxis = {

                            type: 'value',
                            axisLabel: {

                                show: true,
                                formatter: function(value) {

                                    if (that.row !== null && that.dataFieldEnum[that.row.fieldEn] != null) {

                                        return [that.dataFieldEnum[that.row.fieldEn][value]];
                                    } else {

                                        return [value];
                                    }
                                }
                            }
                        };
                        this.drawChart();
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            drawChart() {

                let myChart = this.eCharts.init(document.getElementById('lineChart_' + this.lineChartSearchForm.commandCode));
                // 指定图表的配置项和数据
                let option = {

                    title: {

                        text: '点数据表'
                    },
                    tooltip: {

                        trigger: 'axis',
                        axisPointer: {

                            type: 'cross',
                            label: {

                                backgroundColor: '#6a7985'
                            }
                        },
                        formatter:(params) => {

                            let relVal = params[0].name;
                            for (let i = 0, l = params.length; i < l; i++) {

                                for (let index = 0; index < this.option.series.length; index++) {

                                    if (this.option.series[index].name === params[i].seriesName) {

                                        let val = '--';
                                        if (params[i].value !== '') {

                                            val = params[i].value;
                                        }
                                        relVal = relVal + '<br/>' + params[i].seriesName + ' : ' + val;
                                        if (this.option.series[index].unit !== '--') {

                                            relVal = relVal + ' ' + this.option.series[index].unit;
                                        }
                                    }
                                }
                            }
                            return relVal;
                        }
                    },
                    legend: {

                        data: this.option.legendArray,
                        top: 25
                    },
                    grid: {

                        left: '3%',
                        right: '6%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.option.xAxis
                        }
                    ],
                    yAxis: [

                        this.option.yAxis
                    ],
                    series: this.option.series
                };
                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);
            },
            stateFormatter(row) {

                if (row.status === '0') {

                    return <span style="color: #67C23A;">正常</span>
                } else if (row.status === '1') {

                    return <span style="color: #F56C6C;">超时</span>;
                } else {

                    return '--';
                }
            },
            // 获取表格引用名称
            getTableRef(tab) {

                return 'table_' + tab.commandCode;
            },
            // 获取折线图的id
            getLineChartId(tab) {

                return 'lineChart_' + tab.commandCode;
            },
            getCellClassName(row) {

                return row.row.comparable === false ? 'disabledCheck' : '';
            },
            getHeaderCellClass(row) {

                return row.columnIndex === 0 ? 'disabledCheck' : '';
            },
            handleSelectableEvent(row) {

                return row.selectionEnable;
            },
            handleSelection(selectionArr) {

                if (selectionArr.length >= 10) {

                    let fieldEnList = [];
                    for (let i = 0; i < selectionArr.length; i++) {

                        fieldEnList[i] = selectionArr[i].fieldEn;
                    }
                    // 把未选中的checkbox禁用
                    this.realDataVoList.forEach((item) => {

                        if (!fieldEnList.includes(item.fieldEn)) {

                            item.selectionEnable = false;
                        }
                    });
                } else {

                    // checkbox全部启用
                    this.realDataVoList.forEach(item => item.selectionEnable = true)
                }
            },
            showChartButton(showChart) {

                return showChart === true;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .report-time {

        right: 10px;
        position: absolute;
        text-align: right;
    }
    .have-data {

        position: absolute;
        top: 400px;
        left: 550px;
    }

    ::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {

        display: none !important;
    }

    ::v-deep .el-dialog.option-dialog-style {

        width: 1250px;
        height: 830px;
    }

    .module-tabs-style {

        height: 720px;
    }

    .statistics {

        display: flex;
        justify-content: space-around;
        .option-btn-normal {

            font-size: 18px;
            color: #409EFF;
            cursor: pointer;
        }
        .statistics-btn {

            height: 20px;
            width: 20px;
            background-image: url('../../../assets/icon/button/statistics.svg');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
</style>
