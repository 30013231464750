import axios from 'axios';
import global from '@/components/Global';

let base = global.serverBase;

// 查询羲和基站设备数据指令列表
export const queryDeviceDataCommandList = params => { return axios.post(base
    + '/es/device/queryDeviceDataCommandList', params)};
// 查询羲和基站设备数据指令字段列表
export const queryDeviceDataCommandFieldValueList = params => {

    return axios({

        url: base + '/es/device/queryDeviceDataCommandFieldValueList',
        method: 'POST',
        ping: true,
        data: params
    });
};
// 查询羲和基站设备数据指令字段报表
export const queryDeviceDataCommandFieldChart = params => { return axios.post(base
    + '/es/device/queryDeviceDataCommandFieldChart', params)};
// 导出设备数据
export const exportEsDeviceData = params => { return axios.post(base + '/es/device/exportDeviceData', params, {responseType:'blob'}) };
